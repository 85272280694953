.banner { 
    width: 100%; 
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: RalewayBold;
    background-color: black;
    color: white;
}

.banner:hover { 
    cursor: default;
}

.message { 
    padding: 5px 0px;
}


@media screen and (max-width: 768px) {

    .banner { 
        width: 100%; 
        height: auto;
        font-size: 75%;
        padding: 10px 5px;
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        font-family: JosefSansRegular;
        background-color: black;
        color: white;
    }
    
    .banner:hover { 
        cursor: default;
    }
    

  
  }