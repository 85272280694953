#map {
    height: 500px;
    width: 100%;
  }
  

.link { 
    text-decoration: none;
}
.container { 
    margin-top: 50px;
}

.message { 
    padding-left: 0 !important;
    width: 100%;
    text-align: start;
    font-size: 125%;
    line-height: 170%;
    font-family: "RalewayRegular";
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 40px;
}
.list { 
    margin-bottom: 50px;
    width: 100%; 
    display: flex;
    flex-direction: column;
}
.itemContainer { 
    width: 100%; 
    margin-bottom: 20px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
}

.storeName, .address { 
    display: flex;
    font-size: 110%;
    align-self: flex-start;
    font-family: "RalewayBold";
}


.button { 
    border: none; 
    outline: none;
    margin-bottom: 10px;
    padding: 5px 20px;
    background-color: black;
    width: 100%; 
    color: white;
    font-family: "RalewayBold";
    font-size: 110%;
}

.button:hover, .button:active { 
    opacity: 85%; 
}

.rowBlue { 
    margin: 0 -110px;
}
.contactMessage { 
    font-size: 150%;
    font-family: "RalewayBlack";
    padding-bottom: 10px;
}

.zalo { 
    font-size: 130%;
    font-family: "RalewayBlack";
    padding-bottom: 30px;
}

.zalo span { 
    border-bottom: 4px solid white;
}

@media screen and (max-width: 768px) {

    .list { 
        margin-top: 50px;
        margin-bottom: 50px;
        width: 100%; 
        display: flex;
        flex-direction: column;
    }

    .message { 
        padding-left: 0 !important;
        text-align: start;
        font-size: 110%;
        line-height: 180%;
        font-family: RalewayRegular;
        margin-top: 10px;
    }

    .itemContainer { 
        width: 100%; 
        margin-bottom: 20px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
    }

    .storeName { 
        display: flex;
        font-size: 100%;
        text-align: start;
        font-family: "RalewayBold";
    }

    .address { 
        font-size: 90%;
        color: grey;
        text-align: start;
        font-family: "RalewayBold";

    }

    .mapIcon {
        max-width: 25px;
    }
    
    .button { 
        display: flex;
        align-items: center;
        justify-content: center;
        border: none; 
        outline: none;
        margin-bottom: 10px;
        padding: 5px 0px;
        background-color: black;
        width: 100%; 
        color: white;
        font-family: "RalewayBold";
        font-size: 100%;
    }
  
  }