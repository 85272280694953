.app {
  text-align: center;
  padding: 30px 100px;
}

@font-face {
  font-family: RalewayBlack;
  src: url(../fonts/Raleway-Black.ttf)
}
@font-face {
  font-family: RalewayBold;
  src: url(../fonts/Raleway-Bold.ttf)
}

@font-face {
  font-family: RalewayMedium;
  src: url(../fonts/Raleway-Medium.ttf)
}

@font-face {
  font-family: RalewayRegular;
  src: url(../fonts/Raleway-Regular.ttf)
}


@font-face {
  font-family: RalewaySemiBold;
  src: url(../fonts/Raleway-SemiBold.ttf)
}

@font-face {
  font-family: RalewayLight;
  src: url(../fonts/Raleway-Light.ttf)
}




@font-face {
  font-family: JosefSansBold;
  src: url(../fonts/JosefinSans-Bold.ttf)
}


@font-face {
  font-family: JosefSansRegular;
  src: url(../fonts/JosefinSans-Regular.ttf)
}

@font-face {
  font-family: JosefSansSemiBold;
  src: url(../fonts/JosefinSans-SemiBold.ttf)
}

@font-face {
  font-family: JosefSansMedium;
  src: url(../fonts/JosefinSans-Medium.ttf)
}

@font-face {
  font-family: JosefSansLight;
  src: url(../fonts/JosefinSans-Light.ttf)
}


@media screen and (max-width: 768px) {

  .app {
    text-align: center;
    padding: 20px 20px;
  }

}
