.container { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px -100px;
}

.message { 
    text-align: center;
    width: 65%;
    font-family: RalewayBlack;
    font-size: 200%;
     
}

.message span { 
    color: #6DCFF6;
    border-bottom: 4px solid #6DCFF6;
}

.message span:hover { 
    color: black; 
    border-bottom: 4px solid black;
    cursor: pointer;
}

.row { 
    padding: 10px 0px;
    width: 50%;
}

.square { 
    width: 100px;
    height: 100px; 
    background-color: #D3D3D3;
}