.accordion { 
    margin-top: 30px;
}

.item { 
    border-radius: 0 !important;
}

.header { 
    font-family: "RalewayBlack";
}

.nutritionRow { 
    display: flex;
}

.category, .question { 
    font-family: "RalewayBlack";
}

.figure, .answer { 
    font-family: "RalewayBold";
    color: grey;
}

.question {
    margin: 5px 0px;

}


.faqs { 
    text-align: start;
}