.menuItem { 
    font-family: "RalewayBlack";
    font-size: 90%;
    margin-bottom: 10px;
    color: black; 
    text-decoration: none;
    text-align: start;
}

.hamburgerMenu {
    position: fixed;
    top: 0;
    right: -200px;
    width: 200px;
    height: 100%;
    background-color: black;
    color: white;
    transition: right 0.3s ease-in-out;
  }
  
  .hamburgerMenu.open {
    right: 0;
  }
  
  .hamburgerMenuButton {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 24px;
    height: 20px;
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
  
  .hamburgerMenuButtonLine {
    width: 100%;
    height: 2px;
    background-color: white;
  }
  
  .hamburgerMenuList {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;
  }
  
  .hamburgerMenuListItem {
    margin-bottom: 20px;
  }
  
  .hamburgerMenuListItem a {
    color: white;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: bold;
  }
  