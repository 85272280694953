.container { 
    display: flex;
    justify-content: space-between;
}
.messageContainer { 
    padding: 100px 0px;
    width: 45%;
    text-align: start;
    align-items: flex-start;
    display: flex; 
    flex-direction: column;
}

.headerOne { 
    font-family: RalewayBlack;
    font-size: 320%;
}

.headerTwo { 
    font-family: RalewayBlack;
    font-size: 170%;
    width: 90%;
}

.button { 
    border: 4px solid black; 
    outline: none;
    margin-bottom: 10px;
    margin-top: 20px;
    padding: 10px 0px;
    border-radius: 4px;
    background-color: transparent;
    width: 200px; 
    color: black;
    font-family: RalewayBlack;
    font-size: 100%;
}

.button:hover, .button:active { 
    opacity: 85%; 
}

.imagesContainer { 
    width: 50%;
    display: flex; 
    align-items: center;
    justify-content: space-around;
}

.img { 
    max-width: 150px;
   
}

@media screen and (max-width: 768px) {

    .container { 
        flex-direction: column;
        align-items: center;
    }

    .messageContainer { 
        padding: 75px 0px 50px 0px;
        width: 100%;
        text-align: start;
        align-items: flex-start;
        display: flex; 
        flex-direction: column;
        align-items: center;
    }
    
    .headerOne { 
        font-family: RalewayBlack;
        font-size: 320%;
        text-align: center;
    }
    
    .headerTwo { 
        text-align: center;
        font-family: RalewayBlack;
        font-size: 150%;
        width: 90%;
    }
    
    .button { 
        border: 4px solid black; 
        outline: none;
        margin-bottom: 10px;
        margin-top: 20px;
        padding: 10px 0px;
        border-radius: 4px;
        background-color: transparent;
        width: 200px; 
        color: black;
        font-family: RalewayBlack;
        font-size: 100%;
    }

    .imagesContainer { 
        width: 95%;
        display: flex; 
        align-items: center;
        justify-content: space-around;
        padding-bottom: 50px;
    }
    
    .img { 
        max-width: 100px;
       
    }
  }