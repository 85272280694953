.blockOne {  
    position: relative;
    margin: 30px -100px 0px -100px;
    height: 500px; 
    display: flex;
    align-items: center;
    background-color: #6DCFF6;
    padding: 0px !important;
}

.blockTwo {  
    position: relative;
    margin: 0px -100px;
    height: 500px; 
    display: flex;
    align-items: center;
    background-color: #FEC66A;
    padding: 0px !important;
}

.blockThree {  
    position: relative;
    margin: 0px -100px;
    height: 500px; 
    display: flex;
    align-items: center;
    background-color: #86CA96;
    padding: 0px !important;
}

.stackedSet { 
    max-width: 275px;
    position: relative;
    top: 0px;
    left: 160px;
}
.cashew { 
    max-width: 150px;
    position: absolute;
    top: 0px;
    right: 250px;
}

.peanutButter { 
    position: absolute;
    max-width: 300px; 
    top: 30px;
    right: 175px;
}

#peanutButter { 
    height: 500px;
    
}

.messageContainer { 
    padding: 50px;
    display: flex;
    align-items: center;
}

.cashewMessage { 
    z-index: 999;
    line-height: 130%;
    width: 99%;
    text-align: start;
    font-family: "RalewayBlack";
    font-size: 450%;
}

.message { 
    line-height: 130%;
    width: 99%;
    text-align: start;
    font-family: "RalewayBlack";
    font-size: 600%;
}

.message span { 
    color: white;
}


@media screen and (max-width: 768px) {
    
.stackedSet { 
    max-width: 230px;
    position: absolute;
    top: 100px;
    left:   70px;
}
.cashew { 
    max-width: 120px;
    position: absolute;
    top: 0px;
    right: 20px;
}

.peanutButter { 
    position: absolute;
    max-width: 300px; 
    top: 30px;
    right: 50px;
}

    .blockOne {  
        margin: 30px -20px 0px -20px;
        height: 500px; 
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: #6DCFF6;
        padding: 0px !important;
    }
    
    .blockTwo {  
        margin: 0px -20px;
        height: 500px; 
        display: flex;
        align-items: center;
        background-color: #FEC66A;
        padding: 0px !important;
    }
    
    .blockThree {  
        margin: 0px -20px;
        height: 500px; 
        display: flex;
        align-items: center;
        background-color: #86CA96;
        padding: 0px !important;
    }
    
    #peanutButter { 
        height: 500px;
        
    }
    
    .messageContainer { 
        padding: 35px 30px 30px 30px;
    }
    
    .cashewMessage { 
        z-index: 999;
        line-height: 130%;
        width: 99%;
        text-align: start;
        font-family: "RalewayBlack";
        font-size: 450%;
    }

    .cashewMessage span { 
        color: white;
    }
    
    .message { 
        z-index: 999;
        max-width: 99%;
        line-height: 130%;
        text-align: start;
        font-family: "RalewayBlack";
        font-size: 500%;
    }
    
    .message span { 
        color: white;
    }
    

  
  }