
.nav { 
    width: 100%;
    display: flex; 
    justify-content: space-between;
}

.logo { 
    max-width: 110px;
}

.menuIcon { 
  max-width: 35px;
}

nav {
    height: 50px;
  }
  
  nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  
  
  nav li {
    margin: 0 10px;
  }

  .icon { 
    margin-left: 5px;
    margin-bottom: 2px;
  }

  .navItem {
    text-decoration: none;
    color: black;
    font-family: "RalewayBlack";
    text-decoration: none;
    font-size: 20px;
    padding: 20px;
    border-radius: 5px;
  }
  
  .navItem span:hover { 
    border-bottom: 4px solid black;
  }
  nav a:hover {
    color: black;
  }

  .menu { 
    border-radius: 0 !important;
  }
  .dropdownToggle { 
    margin-top: 3px;
    background-color: transparent !important;
    color: black !important;
    border: none !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    font-size: 20px !important;
    border-radius: 0 !important;
    font-family: "RalewayBlack" !important;
  }

  .dropdownItem { 
    font-size: 20px !important;
    font-family: "RalewayBlack" !important;
  }


  @media screen and (max-width: 768px) {

    .logo { 
      max-width: 90px;
    }

  
  }