.container { 
    height: auto;
    width: 100%; 
    background-color: #6DCFF6;
    display: flex;
    justify-content: center;
    align-items: center;
}

.title { 
    margin-top: 4px;
    color: white;
}
.innerBox { 
    display: flex;
    align-items: center;
    width: 100%;
    padding: 60px 100px 50px 100px;
}

.logoRow { 
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
    font-family: JosefSansMedium;
}

.footerItem  { 
    margin-bottom: 25px;
    font-size: 120%;
    color: white; 
    text-decoration: none;
    font-family: "RalewayBlack";
  }

.logo {
    max-width: 125px;
   
}

.font { 
    font-family: RalewayBlack;
    color: white;
}

.footerMenu { 
    margin-top: 5px;
    width: 100%; 
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    font-family: RalewayBlack;
    color: white;

}

.menuItem { 
    margin-bottom: 10px;
    color: white;
}

.menuItem:hover { 
    text-decoration: underline;
}

.instagram { 
    max-width: 20px;
    margin-right: 15px;
}





@media screen and (max-width: 768px) {

    .container { 
        height: auto;
        width: 100%; 
        background-color: #6DCFF6;
        display: flex;
        align-items: center;
    }

  .footerItem  { 
    margin-bottom: 15px;
    color: white; 
    text-decoration: none;
    font-size: 100%;
    font-family: "RalewayBlack";
  }

  .innerBox { 
    text-align: start;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 40px 20px 35px 20px;
}

.logoRow { 
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: RalewayBlack;
}

.logo {
    max-width: 100px;
    margin-bottom: 20px;
}

.title { 
    font-size: 80%;
    margin-top: 4px;
    color: white;
}

  }
