.productDetails { 
    position: relative;
}

.slick-dots {
    position: absolute;
    
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  
  .slick-dots li {
    margin: 0 5px;
    list-style: none;
  }
  
  .slick-dots li button {
    background: white;
    border: none;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    cursor: pointer;
  }
 
  
  
  
  
  

.container { 
    margin-top: 70px;
    margin-bottom: 70px;
    padding-left: 0 !important;
}

.infoContainer { 
    width: 100%;
    padding-left: 3%;
}

.square { 
    width: 100%; 
    background-color: #d3d3d3;
}

.productTitle { 
    text-align: start;
    line-height: 120%;
    font-size: 350%;
    font-family: "RalewayBlack";
    margin-bottom: 10px;
}

.productSize { 
    text-align: start;
    font-size: 180%;
    font-family: "RalewayBlack";
    margin-bottom: 10px;
}

.productPrice { 
    text-align: start;
    font-size: 150%;
    font-family: "RalewayBold";
    margin-bottom: 15px;
}

.productDescription {
    text-align: start; 
    font-size: 100%;
    font-family: "RalewayRegular";
    font-weight: bold;
    margin-bottom: 15px;
}

.ingredients { 
    text-align: start; 
    font-size: 100%;
    font-family: "RalewayBold";
    margin-bottom: 15px;
}

.ingredients span { 
    font-family: "RalewayRegular";
    font-weight: bold;
}


.order { 
    width: 100%; 
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
}

.orderText { 
    font-size: 120%;
    font-family: "RalewayBlack";
}

.menu { 
    width: 100% !important; 
    border-radius: 0 !important;
}

.button { 
        border: 4px solid black !important; 
        outline: none !important;
        margin-bottom: 10px !important;
        margin-top: 10px !important;
        padding: 10px 0px !important;
        border-radius: 4px !important;
        background-color: transparent !important;
        width: 100% !important; 
        color: black !important;
        font-family: RalewayBlack !important;
        font-size: 100% !important;  
}

.dropdownToggle { 
    width: 100% !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    border-radius: 0 !important;
    font-family: "RalewayBold" !important;
    font-size: 110% !important;
  }

  .dropdownItem { 
    width: 100% !important;
    font-family: "RalewayBold" !important;
  }

  @media screen and (max-width: 768px) {

    .container { 
        margin-top: 50px;
        margin-bottom: 70px;
        padding-left: 0 !important;
    }

    .productTitle { 
        text-align: start;
        font-size: 200%;
        font-family: "RalewayBlack";
        margin-top: 20px;
        margin-bottom: 10px;
    }
    
    .productPrice { 
        text-align: start;
        font-size: 140%;
        font-family: "RalewayBold";
        margin-bottom: 10px;
    }
    
    .productDescription {
        text-align: start; 
        font-size: 110%;
        font-family: "RalewayRegular";
        font-weight: bold;
        margin-bottom: 15px;
    }
    
    .ingredients { 
        text-align: start; 
        font-size: 110%;
        font-family: "RalewayBold";
        margin-bottom: 15px;
    }

    .ingredients span { 
        font-family: "RalewayRegular";
        font-weight: bold;
    }
    
    
    .order { 
        width: 100%; 
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid black;
    }
    
    .orderText { 
        font-size: 120%;
        font-family: "RalewayBlack";
    }
    
  
  }