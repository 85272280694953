.container { 
    margin-top: 50px;
    
}
.card { 
    border: none !important;
    width: 90% !important;
    outline: none;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.row { 
  margin-top: 20px;
}

.col {
    padding-left: 0 !important;
    margin-bottom: 30px;
}

.square { 
    position: relative;
    width: 100%;
}

.relativeContainer { 
    position: relative;
}

.size { 
    position: absolute;
    top: 0; 
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black; 
    padding: 5px 0px;
    width: 60px; 
    height: 25px; 
    font-family: RalewayBold;
    color: white;
}

.productSize { 
    padding: 20px 0px 0px 0px;
    font-size: 130%;
    font-family: RalewayBlack;
    color: #44C8F5;
}

.productName { 
    padding: 20px 0px 10px 0px;
    font-size: 130%;
    font-family: RalewayBlack;
}

.productPrice { 
    padding-bottom: 10px;
    font-size: 110%; 
    color: #363636;
    font-family: RalewayBold;
}

.button { 
    border: 4px solid black; 
    outline: none;
    margin-bottom: 10px;
    margin-top: 10px;
    padding: 10px 0px;
    border-radius: 4px;
    background-color: transparent;
    width: 200px; 
    color: black;
    font-family: RalewayBlack;
    font-size: 100%;
}

.button:hover, .button:active { 
    opacity: 85%; 
}


@media screen and (max-width: 768px) {

    .size { 
        position: absolute;
        top: 0; 
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: black; 
        padding: 5px 0px;
        width: 45px; 
        font-size: 85%;
        height: 25px; 
        font-family: RalewayBold;
        color: white;
    }

    .productSize { 
        padding: 20px 0px 0px 0px;
        font-size: 100%;
        font-family: RalewayBlack;
        color: #44C8F5;
    }

    .productName { 
        width: 100%;
        padding: 10px 0px 5px 0px;
        font-size: 100%;
        font-family: RalewayBlack;
    }
    
    .productPrice { 
        padding-bottom: 5px;
        font-size: 100%; 
        font-family: RalewayBold;
    }

    .card { 
        border: none !important;
        width: 100% !important;
        outline: none;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .button { 
        width: 100%;
    }
    

  
  }