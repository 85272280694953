.message { 
    font-family: "RalewayBlack";
    font-size: 275%; 
}

.container { 
    padding-top: 20px;
    padding-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 768px) {

    .message { 
        font-family: "RalewayBlack";
        font-size: 180%; 
    }
    
    .container { 
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

  
  }