.container { 
    margin-top: 50px;
}


.card { 
    border: none !important;
    width: 90% !important;
    outline: none;
    display: flex;
    flex-direction: column;
}

.card:hover { 
    cursor: pointer;
}

.row { 
    margin-top: 20px;
}

.col {
    padding-left: 0 !important;
    margin-bottom: 30px;
}

.recipeImage { 
    max-width: 100%;
}
.square { 
    width: 100%;
    height: 300px; 
    background-color: #D3D3D3;
    
}

.productName { 
    text-align: center;
    padding: 20px 0px 10px 0px;
    font-size: 150%;
    font-family: RalewayBlack;
}


.button { 
    border: 4px solid black !important; 
    outline: none !important;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    padding: 10px 0px !important;
    border-radius: 4px !important;
    background-color: transparent !important;
    width: 100% !important; 
    color: black !important;
    font-family: RalewayBlack !important;
    font-size: 120% !important;
}

.button:hover, .button:active { 
    opacity: 85%; 
    color: transparent;
}

